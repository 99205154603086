import React, { memo } from 'react'
import { styled } from '@mui/material'
import DotGrid from 'images/dot-grid.svg'

const StyledDotGrid = styled(DotGrid)({
  width: '60px',
  margin: '0 auto',
  display: 'block',
})

const Motif = () => {
  return <StyledDotGrid />
}

export default memo(Motif)
