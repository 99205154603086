import React, { memo } from 'react'
import { Box, styled } from '@mui/material'

const StyledBox = styled(Box)(({ theme }) => ({
  width: '40px',
  height: '1px',
  lineHeight: '1px',
  background: theme.palette.primary.main,
  [theme.breakpoints.up('lg')]: {
    width: '60px',
  },
  '&.bottom': {
    margin: '2rem auto',
  },
  '&.left': {
    marginRight: '20px',
  },
}))

const Divider = ({ ...props }) => {
  return <StyledBox {...props} />
}

export default memo(Divider)
