import React, { memo } from 'react'
import loadable from '@loadable/component'

import { InternalButtonLinkProps } from '@atoms/buttonLinks/InternalButtonLink'
import { ExternalButtonLinkProps } from '@atoms/buttonLinks/ExternalButtonLink'

const InternalButtonLink = loadable(
  () => import('@atoms/buttonLinks/InternalButtonLink')
)
const ExternalButtonLink = loadable(
  () => import('@atoms/buttonLinks/ExternalButtonLink')
)

export type buttonLinkProps =
  | Omit<InternalButtonLinkProps, 'children' | 'slug' | 'sx'>
  | ExternalButtonLinkProps

export interface CmsLinkButtonProps {
  button: Queries.DatoCmsButton

  buttonLinkProps?: buttonLinkProps
}

const Button = ({ button, buttonLinkProps }: CmsLinkButtonProps) => {
  if (
    button.isInternal &&
    button.internalLink &&
    (button.internalLink.slug ||
      button.internalLink.model.apiKey === 'homepage')
  ) {
    return (
      <InternalButtonLink
        slug={button.internalLink.slug}
        apiKey={button.internalLink.model.apiKey}
        {...(buttonLinkProps as Omit<
          InternalButtonLinkProps,
          'children' | 'slug'
        >)}
      >
        {button.label}
      </InternalButtonLink>
    )
  } else if (button?.externalLinkUrl) {
    return (
      <ExternalButtonLink
        href={button.externalLinkUrl}
        {...(buttonLinkProps as ExternalButtonLinkProps)}
      >
        {button.label}
      </ExternalButtonLink>
    )
  } else {
    return <></>
  }
}

export default memo(Button)
