import React, { memo } from 'react'
import { Typography, TypographyProps, styled } from '@mui/material'

import { BreakpointProps } from 'constants/breakpoint-props'
import Flex from '@atoms/layout/Flex'
import Divider from '@atoms/layout/Divider'

interface BaseProps {
  text: string
  color?: string
  flexProps?: object
  typographyProps?: TypographyProps
  borderType?: 'bottom' | 'left' | 'leftToBottom'
}

export type SubtitleProps = BaseProps &
  (
    | {
        borderType?: 'bottom'
        breakpoint?: never
      }
    | {
        borderType?: 'left'
        breakpoint?: never
      }
    | {
        borderType?: 'leftToBottom'
        breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
      }
  )

const StyledFlex = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'breakpoint',
})<BreakpointProps>(({ breakpoint, theme }) => ({
  flexDirection: 'column-reverse',
  alignItems: 'center',
  ['.divider']: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up(breakpoint)]: {
    flexDirection: 'row',
    ['.divider']: {
      marginTop: 0,
      marginBottom: 0,
      marginRight: theme.spacing(2),
    },
  },
}))

const Heading = ({ text, borderType, color, typographyProps }: BaseProps) => (
  <Typography
    variant="subtitle1"
    color={color}
    className={borderType}
    {...typographyProps}
    sx={{
      '&.bottom': {
        textAlign: 'center',
      },
    }}
  >
    {text}
  </Typography>
)

const Subtitle = ({
  text,
  borderType,
  color,
  flexProps,
  typographyProps,
  breakpoint,
}: SubtitleProps) => {
  const content = () => {
    switch (borderType) {
      case 'left':
        return (
          <Flex alignCenter sx={{ mb: 2 }} {...flexProps}>
            <Divider className={borderType} />
            <Heading
              text={text}
              borderType={borderType}
              color={color}
              typographyProps={typographyProps}
            />
          </Flex>
        )
      case 'bottom':
        return (
          <>
            <Heading
              text={text}
              borderType={borderType}
              color={color}
              typographyProps={typographyProps}
            />
            <Divider className={borderType} />
          </>
        )
      case 'leftToBottom':
        return (
          <>
            {breakpoint && (
              <StyledFlex
                breakpoint={breakpoint}
                className="vertical-center"
                sx={{ mb: 2 }}
                {...flexProps}
              >
                <Divider className={`divider ${borderType}`} />
                <Heading
                  text={text}
                  borderType={borderType}
                  color={color}
                  typographyProps={typographyProps}
                />
              </StyledFlex>
            )}
          </>
        )

      default:
        return (
          <Heading
            text={text}
            borderType={borderType}
            color={color}
            typographyProps={typographyProps}
          />
        )
    }
  }

  const Content = <>{content()}</>
  return <>{Content}</>
}

export default memo(Subtitle)
