import React, { memo } from 'react'
import { Typography, TypographyProps } from '@mui/material'
import LineBreaks, { LineBreaksProps } from '@atoms/text/LineBreaks'

export interface MainHeadingProps {
  typographyProps: TypographyProps
  lineBreaksProps: LineBreaksProps
}

const MainHeading = ({
  typographyProps,
  lineBreaksProps,
}: MainHeadingProps) => {
  return (
    <Typography {...typographyProps}>
      <LineBreaks {...lineBreaksProps} />
    </Typography>
  )
}

export default memo(MainHeading)
